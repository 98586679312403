// NeedAuth.jsx
import React from 'react';
import PropTypes from 'prop-types';

import { color } from '../style/variables.js';
import ChunkLoading from '../component/ChunkLoading.jsx';

const defaultAuthing = <ChunkLoading durationSec={4} color={color.yellow} />;

class NeedAuth extends React.PureComponent {
  state = { isServer: true };
  nextTick = null;

  componentDidMount() {
    const { isAuthed, token, digestJwtToken } = this.props;
    if (!isAuthed && token) {
      digestJwtToken({ token });
    }
    this.nextTick = setTimeout(() => this.setState({ isServer: false }), 0);
  }

  componentDidUpdate() {
    const { isAuthed, token, digestJwtToken } = this.props;
    if (!isAuthed && token) {
      digestJwtToken({ token });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.nextTick);
  }

  render() {
    const { isServer } = this.state;
    const {
      isAuthed,
      token,
      children,
      renderAuthed,
      renderAuthing,
      renderNeedAuth,
    } = this.props;
    if (children) {
      const childrenArray = children instanceof Array ? children : [children];
      const [authed, authing = defaultAuthing, needAuth] = [
        'authed',
        'authing',
        'needAuth',
      ].map(key =>
        childrenArray.find(child => key === child.props['data-key'])
      );

      if (isAuthed) {
        return authed || null;
      } else if (isServer || token) {
        return authing || null;
      } else {
        return needAuth || null;
      }
    } else {
      if (isAuthed) {
        return renderAuthed ? renderAuthed() : null;
      } else if (isServer || token) {
        return renderAuthing ? renderAuthing() : defaultAuthing;
      } else {
        return renderNeedAuth ? renderNeedAuth() : null;
      }
    }
  }
}

NeedAuth.propTypes = {
  isAuthed: PropTypes.bool.isRequired,
  token: PropTypes.string,
  children: PropTypes.node,
  renderAuthed: PropTypes.func,
  renderAuthing: PropTypes.func,
  renderNeedAuth: PropTypes.func,
  digestJwtToken: PropTypes.func.isRequired,
};

NeedAuth.defaultProps = {
  token: '',
};

export default NeedAuth;
