// NeedAuth.js
import { connect } from 'react-redux';
import getMeData from '../selector/getMeData.js';
import digestJwtToken from '../action/digestJwtToken.js';
import NeedAuth from '../component/NeedAuth.jsx';

const mapStateToProps = state => {
  return {
    isAuthed: !!getMeData(state, 'id'),
    token: getMeData(state, 'token'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    digestJwtToken: ({ token }) => dispatch(digestJwtToken({ token })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NeedAuth);
