// withNotificationPaddingMonitor.js
'use strict';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import { MERGE_OPERATION_DATA } from '../ActionTypes.js';
import getDisplayName from '../resource/getDisplayName.js';
import { footerNavigationHeight as defaultFooterNavigationHeight } from '../style/variables.js';

const debouncedUpdateFooterNavigationHeight = debounce(
  ({ height, dispatch }) => {
    dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['footerNavigation'],
        data: {
          height,
        },
      },
    });
  },
  500 // TODO: remote config
);

const mapDispatchToProps = dispatch => {
  return {
    updateFooterNavigationHeight: ({ height }) =>
      debouncedUpdateFooterNavigationHeight({ height, dispatch }),
  };
};

const withNotificationPaddingMonitor = BaseComponent => {
  class WithNotificationPaddingMonitor extends React.PureComponent {
    componentDidMount() {
      const { footerNavigationHeight, updateFooterNavigationHeight } =
        this.props;

      updateFooterNavigationHeight({
        height: footerNavigationHeight,
      });
    }

    componentDidUpdate(prevProps) {
      const { footerNavigationHeight, updateFooterNavigationHeight } =
        this.props;
      const { footerNavigationHeight: prevFooterNavigationHeight } = prevProps;

      if (prevFooterNavigationHeight !== footerNavigationHeight) {
        updateFooterNavigationHeight({
          height: footerNavigationHeight,
        });
      }
    }

    componentWillUnmount() {
      const { updateFooterNavigationHeight } = this.props;

      updateFooterNavigationHeight({
        height: 0,
      });
    }

    render() {
      const {
        children,
        updateFooterNavigationHeight,
        footerNavigationHeight,
        ...restProps
      } = this.props;

      return <BaseComponent {...restProps} />;
    }
  }

  WithNotificationPaddingMonitor.propTypes = {
    footerNavigationHeight: PropTypes.number,
    children: PropTypes.element,
    updateFooterNavigationHeight: PropTypes.func,
  };

  WithNotificationPaddingMonitor.defaultProps = {
    footerNavigationHeight: defaultFooterNavigationHeight,
    children: null,
    updateFooterNavigationHeight: () => null,
  };

  WithNotificationPaddingMonitor.displayName = `WithNotificationPaddingMonitor(${getDisplayName(
    BaseComponent
  )})`;

  return connect(null, mapDispatchToProps)(WithNotificationPaddingMonitor);
};

export default withNotificationPaddingMonitor;
